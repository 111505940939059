<template>
  <div class="panel panel-default">
    <div class="panel-heading text-center">
      <h4>
        {{ name }}
        <span class="pl-2" v-if="type == 'vertical'">
          <i style="font-size: 25px" class="fa fa-mobile"></i
        ></span>
        <span class="pl-2" v-else>
          <i style="font-size: 25px" class="fa fa-desktop"></i>
        </span>
      </h4>
    </div>
    <div class="panel-body">
      <div v-if="selectedMovies.length < 1">
        <div class="text-center not-selected">
          {{ $t("not_selected_movies") }}
        </div>
      </div>
      <draggable
        v-else
        class="list-group"
        tag="ul"
        group="vertical"
        :list="selectedMovies"
        v-bind="dragOptions"
        @start="drag = true"
        @end="drag = false"
        @change="changeSort"
      >
        <transition-group type="transition" name="flip-list">
          <li
            class="list-group-item"
            v-for="(element, index) in selectedMovies"
            :key="index"
          >
            {{ element.name }}
          </li>
        </transition-group>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "FormOrderMovie",
  props: {
    selectedMovies: {
      type: Array,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  components: {
    draggable
  },
  data() {
    return {
      enabled: true,
      drag: false
    };
  },
  methods: {
    changeSort() {
      this.$emit("update:selectedMovies", this.selectedMovies);
    }
  }
};
</script>
