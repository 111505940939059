<template>
  <div class="row">
    <div class="col-12 col-md-6 py-2">
      <select-movie-form
        :selectedMovies.sync="selectedVerticalMovies"
        @loadData="loadVerticalData"
        :name="'Vertical'"
        :type="'vertical'"
      >
      </select-movie-form>
    </div>
    <div class="col-12 col-md-6 py-2">
      <select-movie-form
        :selectedMovies.sync="selectedHorizontalMovies"
        @loadData="loadHorizontalData"
        :name="'Horizontal'"
        :type="'horizontal'"
      >
      </select-movie-form>
    </div>
    <div class="col-12">
      <form>
        <div class="from-group">
          <input
            class="form-control"
            data-vv-scope="step1"
            type="hidden"
            name="selectedMovies"
            v-model="selectedMoviesInput"
            v-validate="'required'"
          />
          <div v-show="errors.has('step1.selectedMovies')" class="text-danger">
            {{ $t("files_required") }}
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<style>
.dz-preview {
  width: 150px !important;
  height: 150px !important;
}

.dz-image img {
  width: 150px !important;
  height: 150px !important;
  object-fit: cover !important;
}
</style>
<script>
import SelectMovieForm from "@/components/AnimationOnIslands/Form/SelectMovie.vue";

export default {
  name: "StepsListMovies",
  props: {
    selectedVerticalMovies: {
      type: Array,
      required: false
    },
    selectedHorizontalMovies: {
      type: Array,
      required: false
    }
  },
  components: {
    SelectMovieForm
  },
  data() {
    return {
      loadVertical: false,
      loadHorizontal: false,
      selectedMoviesInput: ""
    };
  },
  watch: {
    selectedVerticalMovies(newVal) {
      if (newVal.length > 0 || this.selectedHorizontalMovies.length > 0) {
        this.selectedMoviesInput = "true";
      } else {
        this.selectedMoviesInput = "";
      }
    },
    selectedHorizontalMovies(newVal) {
      if (newVal.length > 0 || this.selectedVerticalMovies.length > 0) {
        this.selectedMoviesInput = "true";
      } else {
        this.selectedMoviesInput = "";
      }
    }
  },
  methods: {
    loadVerticalData(bool) {
      this.loadVertical = bool;
      if (this.loadVertical == false && this.loadHorizontal == false) {
        this.$emit("listLoad", false);
      } else {
        this.$emit("listLoad", true);
      }
    },
    loadHorizontalData(bool) {
      this.loadHorizontal = bool;
      this.loadHorizontal = bool;
      if (this.loadHorizontal == false && this.loadVertical == false) {
        this.$emit("listLoad", false);
      } else {
        this.$emit("listLoad", true);
      }
    }
  }
};
</script>

<style>
.dataTables_length,
.dataTables_paginate,
.dataTables_filter,
.dataTables_info {
  display: none !important;
}
.dataTables_wrapper {
  padding-bottom: 0;
}
</style>
