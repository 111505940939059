<template>
  <div class="row">
    <div class="col-12 col-md-6 py-2">
      <order-movie-form
        :selectedMovies.sync="selectedVerticalMovies"
        :name="'Vertical'"
        :type="'vertical'"
      >
      </order-movie-form>
    </div>
    <div class="col-12 col-md-6 py-2">
      <order-movie-form
        :selectedMovies.sync="selectedHorizontalMovies"
        :name="'Horizontal'"
        :type="'horizontal'"
      ></order-movie-form>
    </div>
  </div>
</template>

<script>
import OrderMovieForm from "@/components/AnimationOnIslands/Form/OrderMovie.vue";

export default {
  name: "StepsOrderMovies",
  props: {
    selectedHorizontalMovies: {
      type: Array,
      required: true
    },
    selectedVerticalMovies: {
      type: Array,
      required: true
    }
  },
  computed: {},
  components: {
    OrderMovieForm
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style>
.ghost {
  opacity: 0.5;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
  background: #ffff;
  margin: 4px 0;
}
.list-group-item i {
  cursor: pointer;
}
</style>
