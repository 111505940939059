<template>
  <div id="wizard" role="application" class="wizard clearfix">
    <div class="steps clearfix">
      <ul role="tablist">
        <li
          v-for="(tab, index) in configTabs"
          :key="`tabidex-${index}`"
          role="tab"
          :class="getTabListClass(index)"
          aria-disabled="false"
          aria-selected="true"
        >
          <a
            id="wizard-t-0"
            href="#wizard-h-0"
            @click.prevent="clickVisibleTab(index)"
            aria-controls="wizard-p-0"
          >
            <span class="number">{{ index + 1 }}</span> {{ tab.name }}</a
          >
        </li>
      </ul>
    </div>
    <div class="content clearfix">
      <h1 id="wizard-h-0" tabindex="-1" class="title current">First Step</h1>
      <div
        v-for="(tab, index) in configTabs"
        v-show="isCurrentTab(index)"
        :class="{ current: isCurrentTab(index) }"
        :key="`body-${index}`"
        class="step-content body"
        id="wizard-p-0"
        role="tabpanel"
        aria-labelledby="wizard-h-0"
        aria-hidden="false"
        :ref="`slotTab${index}`"
      >
        <slot :name="`content-${index + 1}`"> </slot>
      </div>
    </div>
    <div class="actions clearfix">
      <div class="col-12">
        <div
          v-if="this.configTabs.length > this.currentTab + 1"
          class="
            btn
            mx-md-2
            my-2
            btn-primary
            d-block d-md-inline-block
            float-md-right
          "
          @click.prevent="nextTab()"
        >
          {{ $t("next") }}
        </div>
        <div
          v-if="this.configTabs.length == this.currentTab + 1"
          class="
            btn
            mx-md-2
            my-2
            btn-primary
            d-block d-md-inline-block
            float-md-right
          "
          @click.prevent="finish()"
        >
          {{ finishBtnText !== "" ? finishBtnText : $t("finish") }}
        </div>
        <div
          v-if="currentTab !== 0"
          class="
            btn
            mx-sm-2 mx-md-0
            my-2
            btn-default
            d-block d-md-inline-block
            float-md-right
          "
          @click.prevent="previousTab()"
        >
          {{ $t("previous") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/wizard.css";

export default {
  name: "Wizard",
  data() {
    return {
      loading: true,
      currentTab: 0,
      visibleTabs: [0],
      name: ""
    };
  },
  created() {
    this.$emit("loading", false);
  },
  props: {
    configTabs: {
      type: Array,
      default: () => [
        {
          name: ""
        }
      ]
    },
    finishBtnText: {
      type: String,
      default: ""
    }
  },
  watch: {
    currentTab(newVal) {
      this.visibleTabs.push(newVal);
      this.$emit("currentStep", newVal + 1);
    }
  },
  methods: {
    isVisibleTab(index) {
      return this.visibleTabs.includes(index);
    },
    isCurrentTab(index) {
      return this.currentTab == index;
    },
    getTabListClass(index) {
      let tabListClass = "";
      if (this.isCurrentTab(index)) {
        tabListClass += "current";
      } else if (this.isVisibleTab(index)) {
        tabListClass += "done";
      } else {
        tabListClass += "disabled";
      }

      return tabListClass;
    },
    clickVisibleTab(index) {
      if (this.isVisibleTab(index)) {
        if (this.currentTab < index) {
          this.validationTab().then(valid => {
            if (valid == true && this.configTabs.length > this.currentTab + 1) {
              this.currentTab = index;
            }
          });
        } else {
          this.currentTab = index;
        }
        this.$emit("clickVisibleTab", this.currentTab + 1);
      }
    },
    nextTab() {
      this.validationTab().then(valid => {
        if (valid == true && this.configTabs.length > this.currentTab + 1) {
          this.currentTab++;
          this.$emit("nextTab", this.currentTab + 1);
        }
      });
    },
    validationTab() {
      return this.$root.$validator
        .validate(`step${this.currentTab + 1}.*`)
        .then(valid => {
          return valid;
        });
    },
    previousTab() {
      if (this.currentTab > 0) {
        this.currentTab--;
        this.$emit("previousTab", this.currentTab + 1);
      }
    },
    finish() {
      this.validationTab().then(valid => {
        if (valid == true) {
          this.$emit("finishSteps", false);
        }
      });
    }
  }
};
</script>
