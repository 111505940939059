<template>
  <div class="row">
    <div class="col-12">
      <wizard
        @currentStep="updateCurrentStep"
        @finishSteps="updatePrimaryMovie"
        :configTabs="configTabs"
        :finishBtnText="finishBtnText"
      >
        <template slot="content-1">
          <list-movies
            ref="listMovies"
            :selectedVerticalMovies.sync="selectedVerticalMovies"
            :selectedHorizontalMovies.sync="selectedHorizontalMovies"
            @listLoad="updateLoadComponent"
          ></list-movies>
        </template>
        <template slot="content-2">
          <order-movies
            ref="orderMovies"
            :selectedVerticalMovies.sync="selectedVerticalMovies"
            :selectedHorizontalMovies.sync="selectedHorizontalMovies"
          ></order-movies
        ></template>
        <template slot="content-3"
          ><preview-movies
            :previewVerticalMovies="previewVerticalMovies"
            :previewHorizontalMovies="previewHorizontalMovies"
            ref="previewMovies"
          ></preview-movies
        ></template>
      </wizard>
    </div>
  </div>
</template>

<style>
.panel-default {
  height: 100%;
}
.not-selected {
  font-weight: 500;
  font-size: 16px;
  color: #777;
  padding: 35px 0;
  letter-spacing: 2px;
}
</style>

<script>
import Wizard from "@/components/base/Wizard.vue";
import ListMovies from "./Steps/ListMovies.vue";
import OrderMovies from "./Steps/OrderMovies.vue";
import PreviewMovies from "./Steps/PreviewMovies.vue";

export default {
  name: "CreateAnimation",
  components: {
    Wizard,
    ListMovies,
    OrderMovies,
    PreviewMovies
  },
  created() {
    this.$emit("loading", false);
    this.previewFileName = Date.now() + ".mp4";
  },
  data() {
    return {
      previewFileName: "",
      selectedVerticalMovies: [],
      selectedHorizontalMovies: [],
      previewVerticalMovies: {
        name: "Vertical",
        type: "vertical",
        movie: {},
        status: "no_selected"
      },
      previewHorizontalMovies: {
        name: "Horizontal",
        type: "horizontal",
        movie: {},
        status: "no_selected"
      },
      configTabs: [
        {
          name: this.$t("selected_movies")
        },
        {
          name: this.$t("order_movies")
        },
        {
          name: this.$t("preview")
        }
      ],
      finishBtnText: this.$t("update_on_site"),
      moviesList: []
    };
  },
  watch: {
    selectedVerticalMovies() {
      if (this.previewVerticalMovies.movie.url) {
        this.previewVerticalMovies.movie = {};
      }
      this.previewVerticalMovies.status =
        this.selectedVerticalMovies.length > 0 ? "" : "no_selected";
    },
    selectedHorizontalMovies() {
      if (this.previewHorizontalMovies.movie.url) {
        this.previewHorizontalMovies.movie = {};
      }
      this.previewHorizontalMovies.status =
        this.selectedHorizontalMovies.length > 0 ? "" : "no_selected";
    }
  },
  methods: {
    urlGeneratePreview(type) {
      return `/island/wyspa2/movies/${type}/generate`;
    },
    urlUpdatePrimaryMovie(type) {
      return `/island/wyspa2/movies/${type}/updatePrimaryMovie`;
    },
    updateLoadComponent(bool) {
      this.$emit("loading", bool);
    },
    async updateCurrentStep(currentStep) {
      if (currentStep == 3) {
        this.$emit("loading", true);
        if (
          this.selectedVerticalMovies.length > 0 &&
          !this.previewVerticalMovies.movie.url
        ) {
          await this.createPreviewMovie("vertical");
        }
        if (
          this.selectedHorizontalMovies.length > 0 &&
          !this.previewHorizontalMovies.movie.url
        ) {
          await this.createPreviewMovie("horizontal");
        }
        this.$emit("loading", false);
      }
    },
    createPreviewMovie(type) {
      return this.$http
        .post(this.urlGeneratePreview(type), {
          newFileName: this.previewFileName,
          movies:
            type == "vertical"
              ? this.selectedVerticalMovies
              : this.selectedHorizontalMovies
        })
        .then(response => {
          let resp = response.data.data;
          if (resp) {
            if (type == "vertical") {
              this.previewVerticalMovies.movie = resp;
            } else if (type == "horizontal") {
              this.previewHorizontalMovies.movie = resp;
            }
          }
          return true;
        })
        .catch(() => {
          this.$toastr.error("Błąd podczas tworzenia filmu !");
          return false;
        });
    },
    updateMovie(type) {
      return this.$http
        .put(this.urlUpdatePrimaryMovie(type), {
          movie:
            type == "vertical"
              ? this.previewVerticalMovies.movie
              : this.previewHorizontalMovies.movie
        })
        .then(() => {
          return true;
        })
        .catch(() => {
          return false;
        });
    },
    async updatePrimaryMovie() {
      this.$emit("loading", true);
      if (this.previewVerticalMovies.movie.path) {
        await this.updateMovie("vertical").then(success => {
          if (success == true) {
            this.$toastr.success("Poprawnie zapisano film w wersji vertical!");
            this.selectedVerticalMovies = [];
          } else {
            this.$toastr.error("Błąd zapisu filmu w wersji vertical!");
          }
        });
      }
      if (this.previewHorizontalMovies.movie.path) {
        await this.updateMovie("horizontal").then(success => {
          if (success == true) {
            this.$toastr.success(
              "Poprawnie zapisano film w wersji horizontal!"
            );
            this.selectedHorizontalMovies = [];
          } else {
            this.$toastr.error("Błąd zapisu filmu w wersji horizontal!");
          }
        });
      }
      this.$emit("loading", false);
    }
  }
};
</script>
