<template>
  <div class="panel panel-default">
    <div class="panel-heading text-center">
      <h4>
        {{ name }}
        <span class="pl-2" v-if="type == 'vertical'">
          <i style="font-size: 25px" class="fa fa-mobile"></i
        ></span>
        <span class="pl-2" v-else>
          <i style="font-size: 25px" class="fa fa-desktop"></i>
        </span>
      </h4>
    </div>
    <div class="panel-body">
      <vue-dropzone
        ref="modalDropzone"
        id="modal-dropzone"
        :options="dropzoneOptions"
        @vdropzone-success="successUpdated"
        @vdropzone-error="failedUpdated"
      >
      </vue-dropzone>
      <DataTables
        @loading="setLoading"
        :ref="`dataTables${type}`"
        :url="urlList"
        :columns_list="columns_list"
        :theadRow="false"
      />
    </div>
  </div>
</template>

<script>
import DataTables from "@/components/base/DataTables.vue";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  name: "FormSelectMovie",
  props: {
    selectedMovies: {
      type: Array,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  components: {
    DataTables
  },
  computed: {
    urlList() {
      return `island/wyspa2/movies/${this.type}/list`;
    }
  },
  data() {
    return {
      columns_list: [
        {
          key: "selected",
          name: "Wybór",
          type: "number",
          sortable: false,
          filterable: false,
          touch: true,
          filter: "btn",
          class: "text-center",
          dont_refresh: true,
          function: (row, data) => this.checkedMovies(row, data),
          btn: (a, row) =>
            `<input type="checkbox" id="movies-${a}" name="movies-${a}" ${
              this.isSelected(row) ? "checked" : ""
            }>`
        },
        {
          key: "name",
          name: "Nazwa",
          type: "text",
          sortable: false,
          filterable: false
        },
        {
          key: "size",
          name: "Wielkość",
          type: "text",
          sortable: false,
          filterable: false
        }
      ],
      selectedMoviesInput: "",
      dropzoneOptions: {
        url: `${this.$http.getBaseUrl()}island/wyspa2/movies/${
          this.type
        }/upload`,
        maxFilesize: 15,
        dictDefaultMessage: this.$t("dropzone_input_messages"),
        displayExistingFile: [],
        headers: {
          Authorization: `${this.$http.getToken()}`,
          Accept: `application/json`
        }
      }
    };
  },
  methods: {
    checkedMovies(row, data) {
      if (this.isSelected(data)) {
        var index = this.selectedMovies.findIndex(element => {
          return element.name == data.name;
        });
        if (index !== -1) {
          this.selectedMovies.splice(index, 1);
        }
      } else {
        this.selectedMovies.push(this.$func.cloneObject(data));
      }
      this.selectedMoviesInput =
        this.selectedMovies.length > 0 ? "selected" : "";
      this.$emit("update:selectedMovies", this.selectedMovies);
    },
    getSelectedMovies() {
      return this.$refs.dataTables.table_list.filter(element => {
        if (element.selected == 1) {
          return element;
        }
      });
    },
    isSelected(row) {
      let el = this.selectedMovies.filter(element => {
        if (element.name == row.name) {
          return element;
        }
      });
      return el.length > 0 ? true : false;
    },
    failedUpdated(file, message) {
      const elements = document.querySelectorAll(".dz-file-preview");
      for (const element of elements) {
        const filename = element.querySelectorAll(".dz-filename span")[0]
          .textContent;
        const errorMessage = element.querySelectorAll(
          ".dz-error-message span"
        )[0];
        if (filename === file.name && message.message !== undefined) {
          errorMessage.textContent = message.message;
        }
      }
    },
    setLoading(body) {
      this.$emit("loadData", body);
    },
    successUpdated(file, response) {
      if (response.data) {
        this.selectedMovies.push(response.data);
        this.$refs[`dataTables${this.type}`].getData();
      }
    }
  }
};
</script>

<style>
.dataTables_length,
.dataTables_paginate,
.dataTables_filter,
.dataTables_info {
  display: none !important;
}
</style>
