<template>
  <div class="row">
    <div class="col-12">
      <ibox :title="$t('animations')">
        <template slot="content" slot-scope="{ setLoading }">
          <div class="py-4">
            <small>
              {{ $t("description_animation_on_islands") }}
            </small>
          </div>
          <div class="hr-line-dashed"></div>

          <create-animation @loading="setLoading"> </create-animation>
        </template>
      </ibox>
    </div>
  </div>
</template>

<script>
import Ibox from "@/components/base/Ibox.vue";
import CreateAnimation from "@/components/AnimationOnIslands/CreateAnimation.vue";

export default {
  name: "AnimationOnIslands",
  components: {
    Ibox,
    CreateAnimation
  },
  data() {
    return {};
  }
};
</script>
