<template>
  <div class="row">
    <div class="col-12 col-md-6 py-2">
      <div class="panel panel-default">
        <div class="panel-heading text-center">
          <h4>
            {{ previewVerticalMovies.name }}
            <span class="pl-2">
              <i style="font-size: 25px" class="fa fa-mobile"></i
            ></span>
          </h4>
        </div>
        <div class="panel-body">
          <div v-if="previewVerticalMovies.status !== ''">
            <div class="text-center not-selected">
              {{ testStatus(previewVerticalMovies.status) }}
            </div>
          </div>
          <video
            style="max-height: 350px"
            id="vertical-video-preview"
            autoplay
            loop
            controls
            muted
            :src="getSrcMovies(previewVerticalMovies.movie.url)"
            v-show="previewVerticalMovies.movie.url !== undefined"
          />
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 py-2">
      <div class="panel panel-default">
        <div class="panel-heading text-center">
          <h4>
            {{ previewHorizontalMovies.name }}
            <span class="pl-2">
              <i style="font-size: 25px" class="fa fa-desktop"></i>
            </span>
          </h4>
        </div>
        <div class="panel-body">
          <div v-if="previewHorizontalMovies.status !== ''">
            <div class="text-center not-selected">
              {{ testStatus(previewHorizontalMovies.status) }}
            </div>
          </div>
          <video
            id="horizontal-video-preview"
            style="max-height: 350px"
            autoplay
            loop
            controls
            muted
            :src="getSrcMovies(previewHorizontalMovies.movie.url)"
            v-show="previewHorizontalMovies.movie.url !== undefined"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StepsPreviewMovies",
  props: {
    previewVerticalMovies: {
      type: Object,
      required: true
    },
    previewHorizontalMovies: {
      type: Object,
      required: true
    }
  },
  computed: {},
  data() {
    return {};
  },
  methods: {
    getSrcMovies(url) {
      if (url !== undefined) {
        var timeInMs = Date.now();
        return url + "?test=" + timeInMs;
      }
    },
    testStatus(status) {
      let text = "";
      switch (status) {
        case "updated":
          text = this.$t("updated_on_site");
          break;
        case "no_selected":
          text = this.$t("not_selected_movies");
          break;
      }
      return text;
    }
  }
};
</script>
